<template>
  <div class="error-wrapper container">
    <div class="imagae-wrap">
      <img src="../assets/error404.svg" alt="" />
    </div>
    <router-link :to="{ name: 'Home' }" class="btn cta-clear"
      ><i class="fas fa-long-arrow-alt-left"></i>back to home
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
};
</script>

<style lang="scss">
.error-wrapper {
  padding-top: 200px;
  padding-bottom: 100px;
  text-align: center;
  .imagae-wrap {
    padding: 2rem;
    max-width: 600px;
    margin: auto;
  }
  .cta-clear {
    border: 1px solid;
    i {
      padding-right: 15px;
    }
  }
}
</style>
